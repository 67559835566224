import React, { Component } from "react"
import Layout from "../components/Layout/layout"
import image  from "../images/forside4.jpg"

class IndexPage extends Component {
  state = {
    smallScreen: false,
    modal: { name: "" },
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this))
    this.resize()
  }

  resize() {
    this.setState({ smallScreen: window.innerWidth <= 840 })
  }

  render() {
    let indexData = this.props.data.allIndexJson.nodes[0]
    return (
      <Layout page={"home"}>
        <h1 className="title">{indexData.title}</h1>
        <div className="home-main">
          <div className="text-frontpage">
            {indexData.text}
            <p><img src={image} style={{ maxWidth: "280px", border: "1px solid black", marginTop: "20px" }} /></p>
          </div>
        </div>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query {
    allIndexJson {
      nodes {
        title
        text
      }
    }
  }
`
